import React, { useEffect, useState } from "react";
import { CloseSVG } from "../../svg/close";
import Select from "react-select";
/* data for selects */

import JobAddPopup from "../../admin/cards/jobs/JobAddPopup";
import Loader from "../../Loader";
import { LoaderSVG } from "../../svg/loading";
import { useContext } from "react";
import { AuthorizedUserContext } from "../../../AuthorizedUserContext";
import avatar from "../../../../assets/img/avatar.png";

function UpdateEmploye(props) {
  const [updateData, setUpdateData] = useState(false);

  const [AddJobView, setAddJobView] = useState(false);

  const [loadingSend, setLoadingSend] = useState(false);
  const User = useContext(AuthorizedUserContext);

  const [nom, setNom] = useState("");
  const [image, setImage] = useState("");
  const [prenom, setPrenom] = useState("");
  const [email, setEmail] = useState("");
  const [personal_email, setPersonalEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [dateNaissance, setDateNaissance] = useState("");
  const [lieuActivite, setLieuActivite] = useState(null);
  const [lieuRattachement, setLieuRattachement] = useState(null);
  const [entreprise, setEntreprise] = useState(null);
  const [contrat, setContrat] = useState(null);
  const [forfait, setForfait] = useState(null);
  const [dateArrivee, setDateArrivee] = useState("");
  const [dateSortie, setDateSortie] = useState("");
  const [fonction, setFonction] = useState(null);
  const [matricule, setMatricule] = useState("");
  const [nPlus1, setNPlus1] = useState(null);
  const [team, setTeam] = useState(null);
  const [history, setHistory] = useState("");
  const [comment, setComment] = useState("");

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", process.env.REACT_APP_JWT_KEY);

  const containerStyle = {
    // Vos propriétés de style vont ici
    ...(loadingSend && { pointerEvents: "none" }),
    opacity: loadingSend ? 0.5 : 1,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_PROXY_URL +
            "employee/" +
            props.slugEmployee +
            "/update",
          {
            method: "GET",
            hostname: process.env.REACT_APP_HOSTNAME,
            headers: myHeaders,
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const jsonData = await response.json();
        setUpdateData(jsonData.user);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Mise à jour des états après récupération des données de l'utilisateur
  useEffect(() => {
    // Vérifier si updateData est défini avant de mettre à jour les états

    if (updateData) {
      setImage(updateData.img_base64);
      setNom(updateData.last_name);
      setPrenom(updateData.first_name);
      setEmail(updateData.email);
      setPersonalEmail(updateData.personal_email);
      setPhone(updateData.phone);
      setDateNaissance(updateData.date_of_birth);
      setLieuActivite({
        value: updateData.activity_location.id,
        label: updateData.activity_location.name,
      });
      setLieuRattachement({
        value: updateData.attachment_location.id,
        label: updateData.attachment_location.name,
      });
      setEntreprise({
        value: updateData.company.id,
        label: updateData.company.name,
      });
      setContrat({
        value: updateData.contract.id,
        label: updateData.contract.name,
      });
      setForfait({
        value: updateData.package.id,
        label: updateData.package.name,
      });
      setDateArrivee(updateData.entry_date);
      setDateSortie(updateData.exit_date);
      setFonction({
        value: updateData.job.id,
        label: updateData.job.name_en + " / " + updateData.job.name_fr,
      });
      setMatricule(updateData.employee_number);
      setNPlus1({
        value: updateData.manager.id,
        label:
          updateData.manager.first_name + " " + updateData.manager.last_name,
      });
      setTeam({
        value: updateData.team.id,
        label: updateData.team.name,
      });
      setComment(updateData.comment);
    }
  }, [updateData]);

  /* remplissage des selects */
  const [lieuxActiviteOptions, setLieuxActiviteOptions] = useState([]);
  const [lieuxRattachementOptions, setLieuxRattachementOptions] = useState([]);
  const [entreprisesOptions, setEntreprisesOptions] = useState([]);
  const [contratsOptions, setContratsOptions] = useState([]);
  const [forfaitsOptions, setForfaitsOptions] = useState([]);
  const [fonctionOptions, setFonctionOptions] = useState([]);
  const [nPlus1Options, setNPlus1Options] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        //activity
        const activitiesResponse = await fetch(
          process.env.REACT_APP_DATA_URL +
            "activity_places.json?" +
            new Date().getTime()
        );
        const activitiesParsed = await activitiesResponse.json();
        setLieuxActiviteOptions(activitiesParsed.activity_places);

        //attachement
        const attachementResponse = await fetch(
          process.env.REACT_APP_DATA_URL +
            "attachment_places.json?" +
            new Date().getTime()
        );
        const attachementParsed = await attachementResponse.json();
        setLieuxRattachementOptions(attachementParsed.attachment_places);

        //companies
        const companiesResponse = await fetch(
          process.env.REACT_APP_DATA_URL +
            "companies.json?" +
            new Date().getTime()
        );
        const companiesParsed = await companiesResponse.json();
        setEntreprisesOptions(companiesParsed.companies);

        //contracts
        const contractsResponse = await fetch(
          process.env.REACT_APP_DATA_URL +
            "contracts.json?" +
            new Date().getTime()
        );
        const contractsParsed = await contractsResponse.json();
        setContratsOptions(contractsParsed.contracts);

        //packages
        const packagesResponse = await fetch(
          process.env.REACT_APP_DATA_URL +
            "contract_packages.json?" +
            new Date().getTime()
        );
        const packagesParsed = await packagesResponse.json();
        setForfaitsOptions(packagesParsed.contract_packages);

        //jobs
        const jobsResponse = await fetch(
          process.env.REACT_APP_DATA_URL + "jobs.json?" + new Date().getTime()
        );
        const jobsParsed = await jobsResponse.json();
        setFonctionOptions(jobsParsed.jobs);

        //managers
        const managersResponse = await fetch(
          process.env.REACT_APP_DATA_URL +
            "managers.json?" +
            new Date().getTime()
        );
        const managersParsed = await managersResponse.json();
        setNPlus1Options(managersParsed.managers);

        //teams
        const teamsResponse = await fetch(
          process.env.REACT_APP_DATA_URL + "teams.json?" + new Date().getTime()
        );
        const teamsParsed = await teamsResponse.json();
        setTeamOptions(teamsParsed.teams);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const addOneJob = (id, fr, en) => {
    setFonctionOptions((prevJobOptions) => [
      ...prevJobOptions,
      {
        id_job: id,
        name_job_fr: fr,
        name_job_eng: en,
      },
    ]);
  };

  const noOptionsMessage = () => "Aucun résultat trouvé";

  const resizeImage = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = document.createElement("img");
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          const scaleFactor = 100 / img.width;
          canvas.width = 100;
          canvas.height = img.height * scaleFactor;
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          resolve(canvas.toDataURL("image/jpeg", 0.8));
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const resizedImage = await resizeImage(file);
      setImage(resizedImage);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    /*     const phoneRegex =
      /^\+(32|33)(-| )?(\d{1,5})(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5})?$/;
    if (!phone.match(phoneRegex)) {
      alert(
        "Le format du téléphone est incorrect. Veuillez inclure +32 ou +33."
      );
      return;
    } */

    setLoadingSend(true);

    // Vous pouvez traiter les données ici
    /*     console.log({
      nom,
      prenom,
      email,
      phone,
      dateNaissance,
      lieuActivite,
      lieuRattachement,
      entreprise,
      contrat,
      forfait,
      dateArrivee,
      dateSortie,
      fonction,
      matricule,
      nPlus1,
      team,
      history,
    });
    console.log(User.id);
    console.log(updateData.id); */

    const requestData = {
      id: updateData.id,
      img_base64: image,
      last_name: nom,
      first_name: prenom,
      email: email,
      personal_email: personal_email,
      phone: phone,
      birth_date: dateNaissance,
      activity_place: lieuActivite ? lieuActivite.value : null,
      attachment_place: lieuRattachement ? lieuRattachement.value : null,
      company: entreprise ? entreprise.value : null,
      type_contract: contrat ? contrat.value : null,
      type_payment: forfait ? forfait.value : null,
      date_arrival_effect: dateArrivee,
      date_departure: dateSortie,
      job: fonction ? fonction.value : null,
      registration: matricule,
      superior: nPlus1 ? nPlus1.value : null,
      team: team ? team.value : null,
      comment: comment,
      status: 4,
      history: history ? history : "Modification",
      validator: User.id,
    };

    // Préparez les options pour la requête POST
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(requestData),
    };

    try {
      // Effectuez la requête POST directement depuis l'application React
      const response = await fetch(
        process.env.REACT_APP_UPDATE_EMPLOYEE,
        requestOptions
      );

      // Vérifiez si la requête OPTIONS préalable est nécessaire
      if (response.status === 200) {
        const result = await response.text();
        // Gérez la réponse du serveur (si nécessaire)
        console.log(result);
        setLoadingSend(false);
        // Call the parent callback to trigger a reload
        props.onReloadParent();
        props.updatePopVisibility(false);
      } else {
        console.error("Erreur de requête:", response.status);
        setLoadingSend(false);
        alert("erreur lors de la mise à jour de l'employée");
      }
    } catch (error) {
      console.error("Erreur:", error);
      setLoadingSend(false);
    }
  };

  return (
    <div className="popup">
      <div className="popup-content update-profil">
        <div style={containerStyle}>
          {loading ? (
            <Loader />
          ) : (
            <div>
              <span
                className="close-button"
                onClick={() => props.updatePopVisibility(false)}
              >
                <CloseSVG />
              </span>
              <h2>Modifier le profil</h2>
              <form onSubmit={handleSubmit}>
                <div className="input-container img-form-input">
                  <label>Image :</label>
                  <img
                    src={image || avatar}
                    alt="Employee"
                    style={{ width: "100px", height: "auto" }}
                  />
                  <input
                    type="file"
                    onChange={handleImageChange}
                    accept="image/*"
                  />
                </div>

                <div className="input-container">
                  <label>Nom :</label>
                  <input
                    type="text"
                    value={nom}
                    onChange={(e) => setNom(e.target.value)}
                    autoComplete="family-name"
                  />
                </div>

                <div className="input-container">
                  <label>Prénom :</label>
                  <input
                    type="text"
                    value={prenom}
                    onChange={(e) => setPrenom(e.target.value)}
                    autoComplete="given-name"
                  />
                </div>

                <div className="input-container">
                  <label>Email Pro :</label>
                  <input
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete="email"
                  />
                </div>

                <div className="input-container">
                  <label>Email Perso :</label>
                  <input
                    type="text"
                    value={personal_email}
                    onChange={(e) => setPersonalEmail(e.target.value)}
                    autoComplete="email"
                  />
                </div>

                <div className="input-container">
                  <label>Telephone :</label>
                  <input
                    type="tel"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    autoComplete="tel"
                  />
                </div>

                <div className="input-container">
                  <label>Date de naissance :</label>
                  <input
                    type="date"
                    value={dateNaissance}
                    onChange={(e) => setDateNaissance(e.target.value)}
                  />
                </div>

                <div className="input-container">
                  <label>Lieu d'activité :</label>
                  <Select
                    options={lieuxActiviteOptions.map((lieu) => ({
                      value: lieu.id_activity_place,
                      label: lieu.name_activity_place,
                    }))}
                    value={lieuActivite}
                    onChange={(selectedOption) =>
                      setLieuActivite(selectedOption)
                    }
                    placeholder="Sélectionnez le lieu d'activité"
                    noOptionsMessage={noOptionsMessage}
                    isClearable={true}
                  />
                </div>

                <div className="input-container">
                  <label>Lieu de rattachement :</label>
                  <Select
                    options={lieuxRattachementOptions.map((lieu) => ({
                      value: lieu.id_attachment_place,
                      label: lieu.name_attachment_place,
                    }))}
                    value={lieuRattachement}
                    onChange={(selectedOption) =>
                      setLieuRattachement(selectedOption)
                    }
                    placeholder="Sélectionnez le lieu de rattachement"
                    noOptionsMessage={noOptionsMessage}
                    isClearable={true}
                  />
                </div>

                <div className="input-container">
                  <label>Entreprise :</label>
                  <Select
                    options={entreprisesOptions.map((entreprise) => ({
                      value: entreprise.id_company,
                      label: entreprise.name_company,
                    }))}
                    value={entreprise}
                    onChange={(selectedOption) => setEntreprise(selectedOption)}
                    placeholder="Sélectionnez l'entreprise"
                    isClearable={true}
                  />
                </div>

                <div className="input-container">
                  <label>Contrat :</label>
                  <Select
                    options={contratsOptions.map((contrat) => ({
                      value: contrat.id_contract,
                      label: contrat.name_contract,
                    }))}
                    value={contrat}
                    onChange={(selectedOption) => setContrat(selectedOption)}
                    placeholder="Sélectionnez le contrat"
                    isClearable={true}
                  />
                </div>

                <div className="input-container">
                  <label>Forfait : </label>
                  <Select
                    options={forfaitsOptions.map((forfait) => ({
                      value: forfait.id_payment,
                      label: forfait.name_payment,
                    }))}
                    value={forfait}
                    onChange={(selectedOption) => setForfait(selectedOption)}
                    placeholder="Sélectionnez le forfait"
                    isClearable={true}
                  />
                </div>

                <div className="input-container">
                  <label>Date d'arrivée :</label>
                  <input
                    type="date"
                    value={dateArrivee}
                    onChange={(e) => setDateArrivee(e.target.value)}
                  />
                </div>

                <div className="input-container">
                  <label>Date de sortie : (Laissez vide si inconnue)</label>
                  <input
                    type="date"
                    value={dateSortie}
                    onChange={(e) => setDateSortie(e.target.value)}
                  />
                </div>

                <div className="input-container">
                  <div className="fonction-fr">
                    <label>Fonction FR/EN :</label>
                    <Select
                      options={fonctionOptions.map((fonction) => ({
                        value: fonction.id_job,
                        label:
                          fonction.name_job_eng +
                          (fonction.name_job_fr
                            ? " / " + fonction.name_job_fr
                            : ""),
                      }))}
                      value={fonction}
                      onChange={(selectedOption) => setFonction(selectedOption)}
                      placeholder="Sélectionnez la fonction"
                      noOptionsMessage={noOptionsMessage}
                      isClearable={true}
                    />
                  </div>

                  <button
                    className="add-job"
                    onClick={() => setAddJobView(true)}
                    type="button"
                  >
                    + Ajouter une fonction
                  </button>
                </div>

                {AddJobView && (
                  <JobAddPopup
                    onAddClick={addOneJob}
                    onCancelAdd={setAddJobView}
                  />
                )}

                <div className="input-container">
                  <label>Matricule :</label>
                  <input
                    type="number"
                    value={matricule}
                    onChange={(e) => setMatricule(e.target.value)}
                  />
                </div>
                <div className="input-container">
                  <label>N+1 :</label>
                  <Select
                    options={nPlus1Options.map((nPlus1) => ({
                      value: nPlus1.id,
                      label: nPlus1.first_name + " " + nPlus1.last_name,
                    }))}
                    value={nPlus1}
                    onChange={(selectedOption) => setNPlus1(selectedOption)}
                    placeholder="Sélectionnez N+1"
                    noOptionsMessage={noOptionsMessage}
                    isClearable={true}
                  />
                </div>
                <div className="input-container">
                  <label>Team :</label>
                  <Select
                    options={teamOptions.map((team) => ({
                      value: team.id_team,
                      label: team.name_team,
                    }))}
                    value={team}
                    onChange={(selectedOption) => setTeam(selectedOption)}
                    placeholder="Sélectionnez l'équipe"
                    noOptionsMessage={noOptionsMessage}
                    isClearable={true}
                  />
                </div>

                <div className="input-container">
                  <label>Commentaire</label>
                  <textarea
                    style={{ width: "100%" }}
                    type="text"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                </div>

                <div className="input-container">
                  <label>Description pour l'historique :</label>
                  <input
                    style={{ minWidth: "450px" }}
                    type="text"
                    value={history}
                    onChange={(e) => setHistory(e.target.value)}
                  />
                </div>

                <div className="buttons mt-4">
                  {loadingSend ? (
                    <LoaderSVG />
                  ) : (
                    <>
                      <button
                        type="button"
                        onClick={() => props.updatePopVisibility(false)}
                        className="btn btn-secondary"
                      >
                        Annuler
                      </button>
                      <button type="submit" className="btn btn-primary">
                        Valider la modification
                      </button>
                    </>
                  )}
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default UpdateEmploye;
