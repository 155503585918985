import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";
import { LoaderSVG } from "../svg/loading";

function Exiting() {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", process.env.REACT_APP_JWT_KEY);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_DATA_URL +
            "employees.json?" +
            new Date().getTime()
        );
        const parsed = await response.json();

        // Filter and sort the data
        const filteredData = parsed.employees.filter(
          (emp) => emp.status === "En cours de sortie"
        );
        const sortedData = sortDataByDate(filteredData);
        setEmployees(sortedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  function sortDataByDate(data) {
    if (data && Array.isArray(data)) {
      return data.slice().sort((a, b) => {
        const dateA = new Date(a.exit_date);
        const dateB = new Date(b.exit_date);
        return dateA - dateB;
      });
    } else {
      return [];
    }
  }

  function formatDatedmy(dateStr) {
    const date = new Date(dateStr);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    return `${formattedDay}/${formattedMonth}/${year}`;
  }

  function LinkToPage(rowData) {
    navigate("/collaborateurs/" + rowData.slug);
  }

  return (
    <div>
      <div className="title-section">
        <h2>Employé.e.s en cours de sortie</h2>
      </div>

      {loading ? (
        <LoaderSVG />
      ) : (
        <DataTable
          value={employees}
          selectionMode="single"
          onSelectionChange={(e) => LinkToPage(e.value)}
        >
          <Column
            field="exit_date"
            header="Jour de sortie"
            body={(rowData) => formatDatedmy(rowData.exit_date)}
          />
          <Column
            header="Nom Prénom "
            body={(rowData) => (
              <strong>
                <span style={{ textTransform: "uppercase" }}>
                  {rowData.last_name}
                </span>{" "}
                {rowData.first_name}
              </strong>
            )}
          />
          <Column
            field="job"
            header="Poste"
            headerClassName="center-title"
            bodyStyle={{
              textAlign: "center",
              textTransform: "uppercase",
            }}
          />
          <Column
            field="contract_type"
            header="Type de Contrat"
            headerClassName="center-title"
            bodyStyle={{ textAlign: "center" }}
          />
          <Column
            field="city"
            header="Ville"
            headerClassName="center-title"
            bodyStyle={{ textAlign: "center" }}
          />
        </DataTable>
      )}
    </div>
  );
}

export default Exiting;
