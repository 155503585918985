import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <Container fluid>
        <Row className="space-beetween">
          <Col className="footer-links">
            <Link to={"/cgu"}>Conditions Générales d'utilisation</Link>
            <Link to={"/confidentialite"}>Politique de confidentialité</Link>
          </Col>
          <Col className="copyright text-right">
            Copyright © 2000-{currentYear} Becoming, Inc. All Rights Reserved
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
