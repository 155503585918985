import React, { useEffect, useState } from "react";
import BreadcrumbComponent from "./Breadcrumb";
import { Row, Tabs } from "react-bootstrap";
import { Tab } from "bootstrap";
import Incoming from "./statut/Incoming";

import Exiting from "./statut/Exiting";
import "../../assets/style/datatables.scss";
import { useLocation } from "react-router-dom";
import Updating from "./statut/Updating";

function Statut() {
  const breadcrumbLinks = [
    { label: "Accueil", to: "/" },
    { label: "Liste des collaborateurs", to: "/collaborateurs" },
    { label: "Statut " },
  ];

  const [key, setKey] = useState("incoming");

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tabParam = searchParams.get("tab");

    if (tabParam) {
      setKey(tabParam);
    }
  }, [location.search]);

  return (
    <div id="page-inout">
      <BreadcrumbComponent links={breadcrumbLinks} />

      <Row>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tab eventKey="incoming" title="Non finalisé">
            <Incoming />
          </Tab>

          <Tab eventKey="updating" title="En cours de modification">
            <Updating />
          </Tab>

          <Tab eventKey="exiting" title="En cours de sortie">
            <Exiting />
          </Tab>
        </Tabs>
      </Row>
    </div>
  );
}

export default Statut;
