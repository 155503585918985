import React, { useState, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Alert, Container, Row } from "react-bootstrap";
import { UserContext } from "./components/UserContext";
import { AuthorizedUserContext } from "./components/AuthorizedUserContext";
import { auth } from "./services/firebase";
import firebase from "./services/firebase";
import Home from "./components/app/Home";
import Menu from "./components/Menu";
import LoginPage from "./components/app/LoginPage";
import Collaborateurs from "./components/app/Collaborateurs";
import ErrorPage from "./components/ErrorPage";
import Reglages from "./components/app/Reglages";
import Admin from "./components/app/Admin";
import Profile from "./components/app/employe/Profile";
import Licence from "./components/app/Licences/Licence.js";
import CreateEmploye from "./components/app/employe/CreateEmploye";
import Loader from "./components/app/Loader";
import Footer from "./components/Footer";
import Cgu from "./components/app/Cgu";
import Privacy from "./components/app/Privacy";
import InOut from "./components/app/InOut";
import Organigramme from "./components/app/Organigramme";
import Freecomers from "./components/app/Freecomers";
import Licences from "./components/app/Licences.js";
import CreateLicence from "./components/app/Licences/CreateLicence.js";
import ScrollToTop from "./ScrollToTop.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Archived from "./components/app/Archived.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import ArchivedProfile from "./components/app/employe/ArchivedProfile.js";
import Statut from "./components/app/Statut.js";

function App() {
  const [authorizedUsers, setAuthorizedUsers] = useState([]);
  const [user, setUser] = useState(false);
  const [authorizedUser, setAuthorizedUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersResponse = await fetch(
          process.env.REACT_APP_DATA_URL + "users.json?" + new Date().getTime()
        );
        const usersParsed = await usersResponse.json();
        setAuthorizedUsers(usersParsed.app_users);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (!loading) {
      const unsubscribe = firebase.auth().onAuthStateChanged((authUser) => {
        if (authUser) {
          setUser(authUser);
          const authenticatedUser = authorizedUsers.find(
            (au) => au.email === authUser.email
          );
          setAuthorizedUser(authenticatedUser);
        } else {
          setUser(null);
          setAuthorizedUser(null);
        }
      });

      return () => unsubscribe();
    }
  }, [loading, authorizedUsers]);

  if (user && !authorizedUsers.map((au) => au.email).includes(user.email)) {
    auth().signOut();
    /*  window.location.reload(false); */
    navigate("/");

    toast.error(
      "Vous n'avez pas les droits pour accéder à l'application, contactez l'admin de l'application",
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      }
    );
  }

  return loading ? (
    <Loader />
  ) : (
    <UserContext.Provider value={user}>
      <div className="app">
        <ScrollToTop />
        <link
          href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
          rel="stylesheet"
        />

        {user && authorizedUsers.map((au) => au.email).includes(user.email) ? (
          <AuthorizedUserContext.Provider value={authorizedUser}>
            <header>
              <Menu user={user} roles={authorizedUser.roles} />
            </header>
            <ToastContainer />
            <Container className="main-container">
              <Row>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/collaborateurs" element={<Collaborateurs />} />
                  <Route
                    path="/collaborateurs/ajouter-collaborateur"
                    element={<CreateEmploye />}
                  />
                  <Route
                    path="/collaborateurs/entree-sortie"
                    element={<InOut />}
                  />
                  <Route path="/collaborateurs/statut" element={<Statut />} />
                  <Route
                    path="/collaborateurs/:profileSlug"
                    Component={Profile}
                  />
                  <Route path="/licences" element={<Licences />} />
                  <Route
                    path="/licences/creer-une-licence"
                    element={<CreateLicence />}
                  />
                  <Route path="/licences/:licenceSlug" Component={Licence} />
                  CreateLicence
                  <Route path="/organigramme" element={<Organigramme />} />
                  <Route path="/archives" element={<Archived />} />
                  <Route
                    path="/archives/:profileSlug"
                    Component={ArchivedProfile}
                  />
                  <Route path="/freecomers" element={<Freecomers />} />
                  <Route path="/reglages" element={<Reglages />} />
                  <Route path="/admin" element={<Admin />} />
                  <Route path="/cgu" element={<Cgu />} />
                  <Route path="/confidentialite" element={<Privacy />} />
                  <Route path="*" element={<ErrorPage />} />
                </Routes>
              </Row>
            </Container>

            <Footer />
          </AuthorizedUserContext.Provider>
        ) : (
          <LoginPage />
        )}
      </div>
    </UserContext.Provider>
  );
}

export default App;
