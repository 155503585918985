import React, { useContext, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import des styles de Quill
import { AuthorizedUserContext } from "../../AuthorizedUserContext";

function Notes() {
  const [notes, setNotes] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editDate, setEditDate] = useState("");
  const [editPerson, setEditPerson] = useState("");
  const UserID = useContext(AuthorizedUserContext).id;

  // Fonction pour formater la date
  const formatDate = (date) => {
    return new Date(date)
      .toLocaleString("fr-FR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      })
      .replace(",", " à");
  };

  // Fonction pour récupérer la dernière note avec les détails de l'éditeur
  const fetchLatestNote = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", process.env.REACT_APP_JWT_KEY);

      const response = await fetch(process.env.REACT_APP_GET_LAST_NOTE, {
        method: "GET",
        headers: myHeaders,
      });

      if (response.ok) {
        const data = await response.json();
        setNotes(data.note_content);
        setEditDate(formatDate(data.edited_at));
        setEditPerson(data.email); // Suppose que l'API renvoie l'email de l'éditeur
      } else {
        console.error("Erreur lors du chargement de la note");
      }
    } catch (error) {
      console.error("Erreur :", error);
    }
  };

  useEffect(() => {
    fetchLatestNote();
  }, [UserID]);

  // Fonction pour sauvegarder la note et afficher le contenu HTML dans la console
  const handleSaveNote = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", process.env.REACT_APP_JWT_KEY);

    const requestData = {
      user_id: UserID,
      note_content: notes,
    };

    // Préparez les options pour la requête POST
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(requestData),
    };

    try {
      // Effectuez la requête POST directement depuis l'application React
      const response = await fetch(
        process.env.REACT_APP_CREATE_NOTE,
        requestOptions
      );

      if (response.ok) {
        console.log("Note sauvegardée avec succès");
        setIsEditing(false);
        setEditDate(formatDate(new Date())); // Met à jour la date d'édition
      } else {
        console.error("Erreur lors de la sauvegarde de la note");
      }
    } catch (error) {
      console.error("Erreur lors de la sauvegarde de la note :", error);
    }
  };

  return (
    <div className="notes">
      <h3>Notes</h3>
      {isEditing ? (
        <ReactQuill value={notes} onChange={setNotes} />
      ) : (
        <div dangerouslySetInnerHTML={{ __html: notes }} />
      )}
      <div className="info-note">
        dernière modification : {editDate} par : {editPerson}
      </div>
      <button
        className="btn btn-note"
        onClick={() => {
          if (isEditing) {
            handleSaveNote(); // Appel de la fonction de sauvegarde lors de l'édition
          } else {
            setIsEditing(true); // Passer en mode édition si on n'est pas déjà en édition
          }
        }}
      >
        {isEditing ? "Sauvegarder" : "Éditer"}
      </button>
    </div>
  );
}

export default Notes;
