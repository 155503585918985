import React, { useEffect, useState, useCallback } from "react";
import { LoaderSVGWhite } from "../../svg/loadingWhite";

function ProfilStatusSync({
  lastUpdate,
  syncGoogle,
  onReloadParent,
  employeeID,
}) {
  const [loading, setLoading] = useState(false);

  const [currentSyncGoogle, setCurrentSyncGoogle] = useState(
    syncGoogle !== null ? Boolean(syncGoogle) : null
  );
  const [allSynced, setAllSynced] = useState(false);

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };

  const myHeaders = new Headers({
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_JWT_KEY || "",
  });

  // Convertir en objet Date
  const lastUpdateDate = new Date(lastUpdate);

  const formattedDate = lastUpdateDate.toLocaleDateString("fr-FR", options);

  useEffect(() => {
    if (!process.env.REACT_APP_JWT_KEY) {
      console.error("JWT key is not defined");
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentSyncGoogle !== true) {
        checkSyncState();
      } else {
        clearInterval(interval);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [currentSyncGoogle]);

  useEffect(() => {
    if (currentSyncGoogle === true) {
      setAllSynced(true);
    }
  }, [currentSyncGoogle]);

  const checkSyncState = useCallback(async () => {
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_PROXY_URL}apis/get-state/${employeeID}`,
        requestOptions
      );

      if (!response.ok) {
        console.error("Erreur de requête:", response.status);
        return;
      }

      // Vérifier si la réponse a un corps avant de l'analyser en JSON
      const responseText = await response.text();
      if (!responseText) {
        setCurrentSyncGoogle(false);
        return;
      }

      let result;
      try {
        result = JSON.parse(responseText);
      } catch (error) {
        console.error("Erreur lors de l'analyse JSON:", error);
        return;
      }

      if (result.google_sync_state === 1) {
        setCurrentSyncGoogle(true);
      } else {
        setCurrentSyncGoogle(false);
      }
    } catch (error) {
      console.error("Erreur:", error);
    }
  }, [employeeID, myHeaders]);

  const updateProfil = useCallback(async () => {
    setLoading(true);

    if (!process.env.REACT_APP_JWT_KEY) {
      console.error("JWT key is not defined");
      setLoading(false);
      return;
    }

    const requestData = { id: employeeID };

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(requestData),
    };

    try {
      const response = await fetch(
        process.env.REACT_APP_SYNC_APIS,
        requestOptions
      );

      if (response.ok) {
        onReloadParent(false);
      } else {
        console.error("Erreur de requête:", response.status);
      }
    } catch (error) {
      console.error("Erreur:", error);
    } finally {
      setLoading(false);
    }
  }, [employeeID, myHeaders, onReloadParent]);

  const statusGoogle = currentSyncGoogle ? "sync-yes" : "sync-no";

  return (
    <div className="bg-gray p-4 mb-4 center sync">
      <h4>Synchronisé :</h4>

      <div>
        Google :{" "}
        <span className={`sync-status ${statusGoogle}`}>
          {currentSyncGoogle === null
            ? "en cours"
            : currentSyncGoogle
            ? `${formattedDate}`
            : "Non"}

          {}
        </span>
      </div>

      <button
        onClick={updateProfil}
        className="mt-2 sync-btn btn btn-primary"
        disabled={loading}
      >
        {loading ? <LoaderSVGWhite /> : "Synchroniser maintenant"}
      </button>
    </div>
  );
}

export default ProfilStatusSync;
