import React, { useEffect, useRef, useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { usePersonForm } from "./usePersonForm";
import { useWizard } from "react-use-wizard";
import slugify from "react-slugify";
export function BasicInfoStep() {
  const { nextStep } = useWizard();
  const { register, setFieldValue, state } = usePersonForm();
  const { touched, errors, values } = state;

  const [firstemail, setFirstemail] = useState("");
  const [lastemail, setLastemail] = useState("");
  const [emailEnd, setEmailEnd] = useState("@becoming-group.com");
  const [personal_email, setPersonal_email] = useState("");

  const completEmail = firstemail + "." + lastemail + emailEnd;

  const completEmailRef = useRef(completEmail);

  // State local pour suivre la validité de chaque champ
  const [fieldValidity, setFieldValidity] = useState({
    firstName: false,
    lastName: false,
    email: false,
    personal_email: false,
  });

  useEffect(() => {
    const previousEmail = completEmailRef.current;

    if (completEmail !== previousEmail) {
      setFieldValue("email", completEmail);

      setFieldValidity({
        firstName: Boolean(values.firstName),
        lastName: Boolean(values.lastName),
        /*  email: Boolean(values.email), */
      });
    }

    completEmailRef.current = completEmail;
  }, [values]);

  // Fonction pour mettre à jour la validité d'un champ
  const updateFieldValidity = (field, isValid) => {
    setFieldValidity((prevValidity) => ({ ...prevValidity, [field]: isValid }));
  };

  // Fonction de validation en temps réel
  const validateField = (field, value) => {
    setFieldValue(field, value);
    const isValidField = value !== "";
    updateFieldValidity(field, isValidField);
  };

  // Custom validation function to check if required fields are not empty
  const isValid = () => {
    return Object.values(fieldValidity).every((isValid) => isValid);
  };

  const handleLastNameChange = (e) => {
    const lastName = e.target.value;
    setLastemail(slugify(lastName));
    validateField("lastName", lastName);
  };

  const handlePersonalEmail = (e) => {
    const personal_emailF = e.target.value;
    setPersonal_email(personal_emailF);
    validateField("personal_email", personal_emailF);
  };

  const handleFirstNameChange = (e) => {
    const firstName = e.target.value;

    setFirstemail(slugify(firstName));
    validateField("firstName", firstName);
  };

  const handleNextStep = (e) => {
    e.preventDefault();

    nextStep();
  };

  return (
    <Container>
      <h3 className="mt-4">NOM* </h3>
      <Form.Group as={Row}>
        <Col>
          <Form.Control
            type="text"
            {...register("lastName")}
            autocomplete="family-name"
            onChange={handleLastNameChange}
          />
        </Col>
      </Form.Group>

      <h3 className="mt-4">Prénom* </h3>
      <Form.Group as={Row}>
        <Col>
          <Form.Control
            type="text"
            {...register("firstName")}
            autocomplete="given-name"
            onChange={handleFirstNameChange}
          />
        </Col>
      </Form.Group>

      <h4 className="mt-4">Email PRO* </h4>
      <Form.Group as={Row}>
        <Col>
          <Form.Control
            type="email"
            disabled
            {...register("email")}
            value={completEmail} // Set the value of the email input field
          />
        </Col>
      </Form.Group>

      <h4 className="mt-4">Email Perso* </h4>
      <Form.Group as={Row}>
        <Col>
          <Form.Control
            type="email"
            {...register("personal_email")}
            autocomplete="email"
            value={personal_email}
            onChange={handlePersonalEmail}
          />
        </Col>
      </Form.Group>

      <h4 className="mt-4">Telephone</h4>
      <Form.Group as={Row}>
        <Col>
          <Form.Control
            type="tel"
            pattern="^\+(32|33)(-| )?(\d{1,5})(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5})?$"
            {...register("tel")}
            placeholder="Ajouter +32 (Belgique) ou +33(France) avant le numéro"
            onChange={(e) => validateField("tel", e.target.value)}
          />
        </Col>
      </Form.Group>

      <h4 className="mt-4">Date de naissance</h4>
      <Form.Group as={Row}>
        <Col>
          <Form.Control
            type="date"
            {...register("birthdate")}
            onChange={(e) => {
              validateField("birthdate", e.target.value);
            }}
          />
        </Col>
      </Form.Group>

      <div className="buttons mt-4">
        <Button
          className="btn btn-primary"
          style={{ minWidth: "225px" }}
          onClick={handleNextStep}
          disabled={
            !isValid() || // Ajoutez votre logique de validation ici
            values.firstName === "" ||
            values.lastName === "" ||
            values.personal_email === ""
            // ||
            // !values.tel.match(
            //   /^\+(32|33)(-| )?(\d{1,5})(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5})?$/
            // )
          }
        >
          Suivant
        </Button>
      </div>
    </Container>
  );
}
