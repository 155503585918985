import React from "react";

import { UserContext } from "../UserContext";
import SearchEmploye from "./home/SearchEmploye";
import ListingInfos from "./home/ListingInfos";
import Notes from "./home/Notes.js";

const Home = () => {
  return (
    <UserContext.Consumer>
      {(data) => {
        return (
          <div>
            <div className="home">
              <SearchEmploye />
              <Notes />

              <ListingInfos />
            </div>
          </div>
        );
      }}
    </UserContext.Consumer>
  );
};

export default Home;
