import React, { useEffect, useState } from "react";

import AttachmentCard from "./cards/cities/AttachmentCard";
import AddAttachment from "./cards/cities/AddAttachment";

import ActivityCard from "./cards/cities/ActivityCard";
import AddActivity from "./cards/cities/AddActivity";
import { async } from "@dabeng/react-orgchart";
import LoadCard from "./cards/LoadCard";

function Cities() {
  const [attachments, setAttachment] = useState([]);
  const [activities, setActivity] = useState([]);

  const [loadingAttachment, setLoadingAttachment] = useState(false);
  const [loadingActivity, setLoadingActivity] = useState(false);

  const loadData = async () => {
    try {
      //contracts
      const attachmentsResponse = await fetch(
        process.env.REACT_APP_DATA_URL +
          "attachment_places.json?" +
          new Date().getTime()
      );

      const attachmentsParsed = await attachmentsResponse.json();

      /*       console.log(attachmentsParsed);
       */
      setAttachment(attachmentsParsed.attachment_places);

      //Forfaits
      const activitiesResponse = await fetch(
        process.env.REACT_APP_DATA_URL +
          "activity_places.json?" +
          new Date().getTime()
      );
      const activitiesParsed = await activitiesResponse.json();
      setActivity(activitiesParsed.activity_places);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    // Chargez les données initiales
    loadData();
  }, []);

  const handleAddAttachement = async (name, address) => {
    setLoadingAttachment(true);
    // Créez un nouvel contrat en ajoutant le nom au tableau contrats
    if (name) {
      // Préparez les en-têtes pour la requête POST
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", process.env.REACT_APP_JWT_KEY);

      const requestData = {
        name_attachment_place: name,
        address: address,
      };

      // Préparez les options pour la requête POST
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestData),
      };

      try {
        // Effectuez la requête POST directement depuis l'application React
        const response = await fetch(
          process.env.REACT_APP_CREATE_ATTACHMENT_PLACE,
          requestOptions
        );

        // Vérifiez si la requête OPTIONS préalable est nécessaire
        if (response.status === 201) {
          const result = await response.text();
          // Gérez la réponse du serveur (si nécessaire)

          let idContract = JSON.parse(result).attachmentId;

          const newAttachement = {
            id_attachment_place: idContract,
            name_attachment_place: name,
            address: address,
          };
          setAttachment([...attachments, newAttachement]);
          setLoadingAttachment(false);
        } else {
          console.error("Erreur de requête:", response.status);
          setLoadingAttachment(false);
        }
      } catch (error) {
        console.error("Erreur:", error);
      }
    }
  };

  const handleAddActivity = async (name) => {
    setLoadingActivity(true);
    // Créez un nouvel contrat en ajoutant le nom au tableau contrats
    if (name) {
      // Préparez les en-têtes pour la requête POST
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", process.env.REACT_APP_JWT_KEY);

      const requestData = {
        name_activity_place: name,
      };

      // Préparez les options pour la requête POST
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestData),
      };

      try {
        // Effectuez la requête POST directement depuis l'application React
        const response = await fetch(
          process.env.REACT_APP_CREATE_ACTIVITY_PLACE,
          requestOptions
        );

        // Vérifiez si la requête OPTIONS préalable est nécessaire
        if (response.status === 201) {
          const result = await response.text();
          // Gérez la réponse du serveur (si nécessaire)

          let idActivity = JSON.parse(result).activityId;

          const newActivity = {
            id_activity_place: idActivity,
            name_activity_place: name,
          };
          setActivity([...activities, newActivity]);
          setLoadingActivity(false);
        } else {
          console.error("Erreur de requête:", response.status);
          setLoadingActivity(false);
        }
      } catch (error) {
        console.error("Erreur:", error);
      }
    }
  };

  return (
    <div>
      <div className="title-section">
        <h2>Lieux</h2>
        <p>Vous pouvez mettre à jour les différents lieux</p>
      </div>

      <div className="p-4 mt-3 bg-gray">
        <h3>Rattachement</h3>
      </div>

      <div className="listing-cards">
        {attachments.map((Attachement) => (
          <AttachmentCard
            key={Attachement.id_attachment_place}
            idAttachement={Attachement.id_attachment_place}
            name={Attachement.name_attachment_place}
            address={Attachement.address}
          />
        ))}

        {loadingAttachment && <LoadCard />}
        <AddAttachment onAddAttachement={handleAddAttachement} />
      </div>

      <div className="p-4 mt-5 bg-gray">
        <h3>Activité</h3>
      </div>
      <div className="listing-cards">
        {activities.map((Activity) => (
          <ActivityCard
            key={Activity.id_activity_place}
            idActivity={Activity.id_activity_place}
            name={Activity.name_activity_place}
          />
        ))}

        {loadingActivity && <LoadCard />}
        <AddActivity onAddActivity={handleAddActivity} />
      </div>
    </div>
  );
}

export default Cities;
