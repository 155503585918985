"use client";

import React, { useEffect, useState, useCallback } from "react";
import { createRoot } from "react-dom/client";
import { Tree, TreeNode } from "react-organizational-chart";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import html2canvas from "html2canvas";
import avatar from "../../assets/img/avatar.png";
import loading from "../../assets/img/loading-buffering.gif";
import { Link } from "react-router-dom";
import "../../assets/style/organigramme.scss";

const LoadingSpinner = () => (
  <img width={30} src={loading} alt="chargement"></img>
);

const Button = ({ children, onClick, className, disabled }) => (
  <div className="export-bloc">
    <button
      onClick={onClick}
      disabled={disabled}
      className={`px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 ${className}`}
    >
      {children}
    </button>

    {disabled && <LoadingSpinner />}
  </div>
);

const Card = ({ children, className }) => (
  <div className={`bg-white shadow-md rounded-lg p-4 ${className}`}>
    {children}
  </div>
);

const Avatar = ({ src, alt, fallback, className }) => (
  <div className={`relative inline-block ${className}`}>
    {src ? (
      <img
        width={40}
        src={src}
        alt={alt}
        className="w-full h-full object-cover rounded-full"
      />
    ) : (
      <div className="w-full h-full flex items-center justify-center bg-gray-200 text-gray-600 rounded-full">
        {fallback}
      </div>
    )}
  </div>
);

const Skeleton = ({ className }) => (
  <div className={`animate-pulse bg-gray-200 rounded ${className}`}></div>
);

export default function Component() {
  const [orgaEmployees, setOrgaEmployees] = useState(null);
  const [loading, setLoading] = useState(true);
  const [exporting, setExporting] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_DATA_URL
        }organigramme.json?${new Date().getTime()}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const parsed = await response.json();
      setOrgaEmployees(parsed.organigramme[0]);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to load organigramme data. Please try again later.");
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const CustomNode = ({ node, isPdfExport = false }) => (
    <Card className={`w-48 ${isPdfExport ? "shadow-none" : ""}`}>
      <Avatar
        src={node.img_base64 || avatar}
        alt={`${node.last_name} ${node.first_name}`}
        fallback={`${node.first_name}${node.last_name}`}
        className="w-12 h-12 mx-auto mb-2"
      />
      <h3 className="text-sm font-semibold text-center">
        {node.last_name} {node.first_name}
      </h3>
      {(node.job || (node.slug && !isPdfExport)) && (
        <div className="text-center mt-2">
          {node.job && <p className="text-xs text-gray-600">{node.job}</p>}
          {node.slug && !isPdfExport && (
            <Link
              to={`/collaborateurs/${node.slug}`}
              className="text-xs text-blue-600 hover:underline"
            >
              Voir le détail
            </Link>
          )}
        </div>
      )}
    </Card>
  );

  const renderTree = useCallback(
    (node, isPdfExport = false) => (
      <TreeNode
        key={node.id}
        label={<CustomNode node={node} isPdfExport={isPdfExport} />}
      >
        {node.children?.map((child) => renderTree(child, isPdfExport))}
      </TreeNode>
    ),
    []
  );

  const calculateTreeDimensions = (node) => {
    let width = 1;
    let depth = 0;
    if (node.children && node.children.length > 0) {
      node.children.forEach((child) => {
        const [childWidth, childDepth] = calculateTreeDimensions(child);
        width += childWidth;
        depth = Math.max(depth, childDepth);
      });
      depth += 1;
    }
    return [width, depth];
  };

  const renderPdfContent = () => {
    const [width, depth] = calculateTreeDimensions(orgaEmployees);
    const pdfWidth = Math.max(width * 200, 2000); // 200px per node, minimum 2000px
    const pdfHeight = (depth + 1) * 200; // 200px per level, +1 for root

    return (
      <div
        className="p-4"
        style={{ width: `${pdfWidth}px`, height: `${pdfHeight}px` }}
      >
        <Tree
          lineWidth={"2px"}
          lineColor={"#bbb"}
          lineBorderRadius={"10px"}
          label={<CustomNode node={orgaEmployees} isPdfExport={true} />}
          orientation="vertical"
        >
          {orgaEmployees &&
            orgaEmployees.children?.map((child) => renderTree(child, true))}
        </Tree>
      </div>
    );
  };

  const exportOrganigrammeAsImage = useCallback(() => {
    setExporting(true);

    const pdfContent = renderPdfContent();
    const tempDiv = document.createElement("div");
    tempDiv.style.position = "absolute";
    tempDiv.style.left = "-9999px";
    document.body.appendChild(tempDiv);

    const root = createRoot(tempDiv);
    root.render(pdfContent);

    setTimeout(() => {
      const [width, depth] = calculateTreeDimensions(orgaEmployees);
      const imgWidth = Math.max(width * 200, 2000);
      const imgHeight = (depth + 1) * 200;
      const bottomMargin = 300; // 200px margin on the bottom

      html2canvas(tempDiv, {
        width: imgWidth,
        height: imgHeight,
        scale: 1,
      }).then((canvas) => {
        // Create a new canvas with bottom margin
        const marginCanvas = document.createElement("canvas");
        marginCanvas.width = imgWidth;
        marginCanvas.height = imgHeight + bottomMargin;
        const ctx = marginCanvas.getContext("2d");

        // Fill the background (optional, remove if you want a transparent background)
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, marginCanvas.width, marginCanvas.height);

        // Draw the original canvas onto the new canvas
        ctx.drawImage(canvas, 0, 0);

        const imgData = marginCanvas.toDataURL("image/png");

        // Create a link element to download the image
        const link = document.createElement("a");
        link.href = imgData;
        link.download = "organigramme.png";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        root.unmount();
        document.body.removeChild(tempDiv);

        setExporting(false);
      });
    }, 100);
  }, [orgaEmployees, renderPdfContent, calculateTreeDimensions]);
  if (loading) {
    return (
      <div className="container mx-auto p-4">
        <Skeleton className="h-10 w-64 mb-4" />
        <Skeleton className="h-10 w-48 mb-4" />
        <div
          className="border rounded-lg overflow-hidden"
          style={{ height: "calc(100vh - 200px)" }}
        >
          <Skeleton className="w-full h-full" />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto p-4">
        <h1 className="text-3xl font-bold mb-4">Organigramme</h1>
        <div className="text-red-500">{error}</div>
        <Button onClick={fetchData} className="mt-4 btn btn-primary">
          Réessayer
        </Button>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">Organigramme</h1>
      <Button
        onClick={exportOrganigrammeAsImage}
        className=" btn btn-primary"
        disabled={exporting}
      >
        Exporter l'organigramme en image
      </Button>
      <div
        className="border rounded-lg overflow-hidden"
        style={{ height: "calc(100vh - 200px)" }}
      >
        <TransformWrapper
          initialScale={0.5}
          minScale={0.1}
          maxScale={2}
          centerOnInit={true}
        >
          <TransformComponent wrapperStyle={{ width: "100%", height: "100%" }}>
            <div className="p-4">
              <Tree
                lineWidth={"2px"}
                lineColor={"#bbb"}
                lineBorderRadius={"10px"}
                label={<CustomNode node={orgaEmployees} />}
                orientation="vertical"
              >
                {orgaEmployees &&
                  orgaEmployees.children?.map((child) => renderTree(child))}
              </Tree>
            </div>
          </TransformComponent>
        </TransformWrapper>
      </div>
    </div>
  );
}
