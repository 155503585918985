import React, { useEffect, useState } from "react";
import JobCard from "./cards/jobs/JobCard";
import AddJob from "./cards/jobs/AddJob";
import Loader from "../Loader";

function Jobs() {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const jobsResponse = await fetch(
          `${process.env.REACT_APP_DATA_URL}jobs.json?${new Date().getTime()}`
        );
        const jobsParsed = await jobsResponse.json();
        setJobs(jobsParsed.jobs);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleAddJob = (id, nameEN, nameFR) => {
    const newJob = {
      id_job: id,
      name_job_fr: nameFR,
      name_job_eng: nameEN,
    };

    setJobs([...jobs, newJob]);
  };

  const filteredJobs = jobs.filter((job) => {
    const searchTermLower = searchTerm.toLowerCase();
    const nameFR = job.name_job_fr ? job.name_job_fr.toLowerCase() : "";
    const nameEN = job.name_job_eng ? job.name_job_eng.toLowerCase() : "";

    return nameFR.includes(searchTermLower) || nameEN.includes(searchTermLower);
  });

  const clearSearch = () => {
    setSearchTerm("");
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="title-section">
            <h2>Jobs</h2>
            <p>Vous pouvez mettre à jour les différents jobs</p>
          </div>

          <div
            className="search-container mt-3"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="search-svg-input">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="15"
                viewBox="0 0 16 15"
                fill="none"
              >
                <path
                  d="M11.2117 9.69676C12.1194 8.45791 12.526 6.92194 12.35 5.39615C12.1741 3.87036 11.4286 2.46727 10.2627 1.4676C9.09687 0.467925 7.59658 -0.0546141 6.06203 0.00452304C4.52748 0.0636602 3.07184 0.700112 1.98634 1.78655C0.900833 2.87298 0.265512 4.32928 0.207481 5.86409C0.14945 7.39889 0.672989 8.89902 1.67336 10.0643C2.67372 11.2297 4.07714 11.9743 5.60284 12.1491C7.12854 12.324 8.66401 11.9163 9.90204 11.0075H9.9011C9.92923 11.045 9.95922 11.0807 9.99297 11.1153L13.6022 14.7251C13.778 14.901 14.0165 14.9999 14.2651 15C14.5138 15.0001 14.7523 14.9014 14.9283 14.7256C15.1042 14.5498 15.203 14.3113 15.2031 14.0625C15.2032 13.8138 15.1045 13.5753 14.9287 13.3993L11.3195 9.78959C11.286 9.75565 11.2499 9.72525 11.2117 9.69676ZM11.4535 6.09264C11.4535 6.76984 11.3202 7.44041 11.0611 8.06606C10.8019 8.69171 10.4222 9.26019 9.94337 9.73904C9.46459 10.2179 8.89619 10.5977 8.27063 10.8569C7.64506 11.116 6.97459 11.2494 6.29749 11.2494C5.62038 11.2494 4.94991 11.116 4.32435 10.8569C3.69879 10.5977 3.13039 10.2179 2.65161 9.73904C2.17282 9.26019 1.79303 8.69171 1.53391 8.06606C1.2748 7.44041 1.14143 6.76984 1.14143 6.09264C1.14143 4.72498 1.68466 3.41333 2.65161 2.44625C3.61855 1.47916 4.93002 0.93586 6.29749 0.93586C7.66496 0.93586 8.97642 1.47916 9.94337 2.44625C10.9103 3.41333 11.4535 4.72498 11.4535 6.09264Z"
                  fill="black"
                />
              </svg>
              <input
                type="text"
                placeholder="Rechercher un job..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-input p-2 mr-2"
              />
            </div>
            {searchTerm.length > 0 && (
              <button
                onClick={clearSearch}
                className="clear-button btn btn-primary"
              >
                Effacer
              </button>
            )}
          </div>

          <div className="listing-cards">
            {filteredJobs.map((job) => (
              <JobCard
                addJob={handleAddJob}
                key={job.id_job}
                idJob={job.id_job}
                job={job}
                searchTerm={searchTerm}
              />
            ))}

            <AddJob onAddJob={handleAddJob} />
          </div>
        </>
      )}
    </div>
  );
}

export default Jobs;
